
// Import React and other necessary hooks
import React, { useState, useEffect } from 'react';
import { useConfigData } from '../Context/ConfigProvider';

const BannerCustom = ({ bannerData, currentTab }) => {
    const appConfig = useConfigData();
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = bannerData?.[currentTab]?.primary || [];
    const [isMobile, setIsMobile] = useState(false);

    // Calculate aspect ratios
    const desktopAspectRatio = (appConfig.BANNER_HEIGHT / appConfig.BANNER_WIDTH) * 100;
    const mobileAspectRatio = (appConfig.BANNER_HEIGHT_MOBILE / appConfig.BANNER_WIDTH_MOBILE) * 100;

    // Effect to handle the delay check for images

    useEffect(() => {
        const checkIsMobile = () => {
            if (typeof window !== 'undefined') {
                setIsMobile(window.innerWidth < 1024);
            }
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    // Auto-slide effect
    useEffect(() => {
        if (images.length > 1) {
            const interval = setInterval(() => {
                setCurrentSlide(current =>
                    current === images.length - 1 ? 0 : current + 1
                );
            }, 4000);

            return () => clearInterval(interval);
        }
    }, [images, currentSlide]);

    const handleDotClick = (index) => {
        setCurrentSlide(index);
    };

    // Render `null` if images are still empty after 1 second
    if (images.length === 0) {
        return null;
    }

    return (
        <div className='w-full relative overflow-hidden'>
            {/* Container */}
            <div
                className='relative w-full h-full'
                style={{
                    paddingBottom: `${isMobile ? mobileAspectRatio : desktopAspectRatio}%`,
                }}
            >
                <div
                    className={`w-full transition-transform duration-500 ease-in-out relative`}
                >
                    {images.map((image, index) => (
                        <div
                            key={`slide-${index}-${image.id}`}
                            className={`w-full transition-transform duration-500 ease-in-out absolute top-0 left-0`}
                            style={{
                                transform: `translateX(${(index - currentSlide) * 100}%)`,
                                visibility: Math.abs(index - currentSlide) <= 1 ? 'visible' : 'hidden'
                            }}
                        >
                            {/* Desktop Image */}
                            <img
                                height={appConfig.BANNER_HEIGHT}
                                width={appConfig.BANNER_WIDTH}
                                className='w-full h-auto hidden lg:block'
                                src={image.desktop_image_url}
                                alt={image.alt_text || ''}
                            />
                            {/* Mobile Image */}
                            <img
                                height={appConfig.BANNER_HEIGHT_MOBILE}
                                width={appConfig.BANNER_WIDTH_MOBILE}
                                className='w-full h-auto lg:hidden'
                                src={image.mobile_image_url}
                                alt={image.alt_text || ''}
                                style={{
                                    paddingBottom: `${mobileAspectRatio}%` // Mobile aspect ratio
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Dots Navigation */}
            <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2 z-20'>
                {images.length > 1 && images.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handleDotClick(index)}
                        className={`w-3 h-3 rounded-full ${currentSlide === index ? 'bg-white' : 'bg-white/50'}`}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default BannerCustom;
