// lib/withConfig.js
import { fetchConfig } from './fetchConfig';

export function withConfig(gssp) {
    return async (context) => {
        const configData = await fetchConfig();
        const result = await gssp(context);
        return {
            ...result,
            props: {
                ...result.props,
                config: configData,
            },
        };
    };
}
