export const isFilterApplied = (filtersList) => {
    if (!filtersList) return false;

    return filtersList.some(filter => {
        const { name = "", values = [] } = filter;

        // Determine selected filters based on the filter name
        const selectedFilters =
            name === "product_price"
                ? values.filter((item, index) => (index === 0 ? item.checked && item?.intended : item.checked))
                : values.filter(item => item.checked);

        // If there are selected filters, return true
        return selectedFilters.length > 0;
    });
};